import React from 'react';
import {FormattedMessage} from 'react-intl';

import temiIconURL from './temi/icon.png';
import temiInsetIconURL from './temi/inset-icon.png';

import controllerIconURL from './controller/icon.png';
import controllerInsetIconURL from './controller/inset-icon.png';
import controllerConnectionIconURL from './controller/illustration.svg';
import controllerConnectionSmallIconURL from './controller/small.svg';

import IFTTTIconURL from './ifttt/ifttt.png';
import IFTTTInsetIconURL from './ifttt/ifttt-inset.png';

import cameraAIIconURL from './AICamera/ai-camera.png';
import cameraAIInsetIconURL from './AICamera/ai-camera-inset.png';
import cameraAIConnectionIconURL from './AICamera/illustration.svg';
import cameraAIConnectionSmallIconURL from './AICamera/small.svg';

import digitalSensorsIconURL from './digitalSensors/digital-sensors.png';
import digitalSensorsInsetIconURL from './digitalSensors/digital-sensors-inset.png';
import digitalSensorsConnectionIconURL from './digitalSensors/illustration.svg';
import digitalSensorsConnectionSmallIconURL from './digitalSensors/small.svg';

import AIMRoverXIconURL from './aimroverx/aimroverx.png';
import AIMRoverXIconURLInsetIconURL from './aimroverx/aimroverx-inset.png';
import AIMRoverXIconURLConnectionIconURL from './aimroverx/illustration.svg';
import AIMRoverXIconURLConnectionSmallIconURL from './aimroverx/small.svg';

import AIMRoverIconURL from './aimroverpi/aimroverpi.png';
import AIMRoverIconURLInsetIconURL from './aimroverpi/aimroverpi-inset.png';
import AIMRoverIconURLConnectionIconURL from './aimroverpi/illustration.svg';
import AIMRoverIconURLConnectionSmallIconURL from './aimroverpi/small.svg';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import robotIconURL from './robot/agvbig.png';
import robotInsetIconURL from './robot/agv-small.png';

import qrcodeIconURL from './qrcode/qrcode.png';
import qrcodeInsetIconURL from './qrcode/qrcode-small.png';


import MQTTSensorsIconURL from './mqttSensors/mqtt-sensors.png';
import MQTTSensorsSmallIconURL from './mqttSensors/mqtt-sensors-small.svg';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="AIMaker Sensors"
                description="Name for the 'AIMaker Sensors' extension"
                id="gui.extension.digitalsensors.name"
            />
        ),
        extensionId: 'digitalSensors',
        iconURL: digitalSensorsIconURL,
        insetIconURL: digitalSensorsInsetIconURL,
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: digitalSensorsConnectionIconURL,
        connectionSmallIconURL: digitalSensorsConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://downloads.aimakerhk.com/sensor-help.html'
    },
    {
        name: 'IFTTT&ThingSpeak',
        extensionId: 'ifttt',
        iconURL: IFTTTIconURL,
        insetIconURL: IFTTTInsetIconURL,
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'QR-Code',
        extensionId: 'qrcode',
        iconURL: qrcodeIconURL,
        insetIconURL: qrcodeInsetIconURL,
        featured: true,
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AI Camera"
                description="Name for the 'Digital Sensors' extension"
                id="gui.extension.aicamera.name"
            />
        ),
        extensionId: 'AICamera',
        bluetoothRequired: false,
        launchPeripheralConnectionFlow: false,
        iconURL: cameraAIIconURL,
        insetIconURL: cameraAIInsetIconURL,
        connectionIconURL: cameraAIConnectionIconURL,
        connectionSmallIconURL: cameraAIConnectionSmallIconURL,
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AIM Rover X"
                description="Name for the 'AIM Rover X' extension"
                id="gui.extension.aimroverx.name"
            />
        ),
        extensionId: 'aimRoverX',
        bluetoothRequired: true,
        launchPeripheralConnectionFlow: true,
        iconURL: AIMRoverXIconURL,
        insetIconURL: AIMRoverXIconURLInsetIconURL,
        connectionIconURL: AIMRoverXIconURLConnectionIconURL,
        connectionSmallIconURL: AIMRoverXIconURLConnectionSmallIconURL,
        featured: true,
        helpLink: 'https://downloads.aimakerhk.com/sensor-help.html'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AIM Rover pi"
                description="Name for the 'AIM Rover pi' extension"
                id="gui.extension.aimroverpi.name"
            />
        ),
        extensionId: 'aimRoverpi',
        bluetoothRequired: true,
        launchPeripheralConnectionFlow: true,
        iconURL: AIMRoverIconURL,
        insetIconURL: AIMRoverIconURLInsetIconURL,
        connectionIconURL: AIMRoverIconURLConnectionIconURL,
        connectionSmallIconURL: AIMRoverIconURLConnectionSmallIconURL,
        featured: true,
        helpLink: 'https://downloads.aimakerhk.com/sensor-help.html'
    },
    {
        name: 'Controller',
        extensionId: 'controller',
        iconURL: controllerIconURL,
        insetIconURL: controllerInsetIconURL,
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: controllerConnectionIconURL,
        connectionSmallIconURL: controllerConnectionSmallIconURL,
        helpLink: 'https://downloads.aimakerhk.com/controller-help.html'
    },
    {
        name: 'AIMaker - AGV  Robot',
        extensionId: 'robot',
        iconURL: robotIconURL,
        insetIconURL: robotInsetIconURL,
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false
    },
    {
        name: 'AIMaker temi',
        extensionId: 'temi',
        iconURL: temiIconURL,
        insetIconURL: temiInsetIconURL,
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'IoT Sensors',
        extensionId: 'mqttSensors',
        iconURL: MQTTSensorsIconURL,
        insetIconURL: MQTTSensorsSmallIconURL,
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    }
];
